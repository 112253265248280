export const tab_en = {
  "mqs_client_tab0": "mqs_client_tab0",
  "mqs_client_tab1": "Genaral",
  "mqs_client_tab3": "Serial Range",
  "PalletSummaryRules": "Pallet Summary Rules",
  "mqs_client_asn_prefix_tab": "ASN Prefix",
  "mqs_client_tab4": "Order Prefix",
  "mqs_client_tab5": "Demand Order Prefix",
  "mqs_client_preadv_prefix_tab": "Preadvice Prefix",
  "mqs_client_upi_prefix_tab": "UPI Prefix",
  "mqs_tab_third_party_billing": "Third Party Billing",
  "mqs_client_sscc_tab": "SSCC",
  "THead": "THead",
};

export const tab_zh = {
  "mqs_client_tab0": "地址",
  "mqs_client_tab1": "综合",
  "mqs_client_tab3": "序列范围",
  "PalletSummaryRules": "Pallet Summary Rules",
  "mqs_client_asn_prefix_tab": "ASN Prefix",
  "mqs_client_tab4": "Order Prefix",
  "mqs_client_tab5": "Demand Order Prefix",
  "mqs_client_preadv_prefix_tab": "Preadvice Prefix",
  "mqs_client_upi_prefix_tab": "UPI Prefix",
  "mqs_tab_third_party_billing": "Third Party Billing",
  "mqs_client_sscc_tab": "SSCC",
  "THead": "表头",
}


export const tabChildPaneName = {
  'demand_order':'需求订单编号',
  'decat_order':'Demand Decatalogused Order ID'
}