import Api from '@api';

import clientCommon from './blocks/clientCommon'
import clientTag from './blocks/clientTag'

import { formConfig } from './config/formConfig'

export default {
  name: 'clientDetail',
  components: {
    clientCommon,
    clientTag
  },
  data() {
    const thead = formConfig(this).thead;
    const config = formConfig(this);
    return {
      clientCommonData: {},
      thead,
      tagFormConfig: config.tagFormConfig,
      refreshComponents:true,
      currentTag:''
    };
  },
  computed:{
    injectData(){
      return this.$route.query || {}
    },
    isEdit(){
      return this.$route.query._type === 'edit'
    },
    isAdd(){
      return this.$route.query._type === 'add'
    }
  },
  mounted() {
    this.$nextTick(()=>{
      if(this.$route.query.CLIENT_ID){
        this.getData();
      }else{
        setTimeout(()=>{
          this.setFormData({STATUS:'Able'})
        },2000)
      }
    })
  },
  activated() {
    //刷新 组件 重新获取组件是编辑、新增还是查看详情
    this.refreshComponents = false
    setTimeout(()=>{
      this.refreshComponents = true
    })

    if(this.$route.query.CLIENT_ID){
      this.getData();
    }
  },
  methods: {
    async getData() {
      let { status, data } = await Api.GetClientById({CLIENT_ID: this.injectData.CLIENT_ID});
      if (Number(status) === 200) {

        let result = JSON.parse(JSON.stringify(data));

        this.setFormData(result)
      }
    },
    /*
    * @param type 操作类型
    * */
    confirmForm(type){
      this.$refs.clientCommon.$refs.clientCommon.$refs.form.validate((valid) => {
        if (valid) {
          let info = this.getTagFormList().find(item=>!item.flag) || {flag:true}
          //console.log(info)
          if(info.flag == false){
            this.currentTag = info.value
            return false
          }else{
            if(type === 'edit'){
              this.updateClientData()
            }else{
              this.addClientData()
            }
            return true
          }
        }else{
          return false
        }
      });
    },
    getTagFormList(){
      let formList = []
      this.tagFormConfig.forEach(item=> {
        this.$refs.clientTag.$refs[item.value][0].$refs.form.validate((valid)=>{
          formList.push({value:item.value,flag:valid})
          if(valid){
            return true
          }else{
            return false
          }
        })
      })
      return formList
    },
    async updateClientData(){
      let params = this.getFormData()
      let {resultFlag,msg} = this.validateFormData(params)
      if(!resultFlag){
        return this.$message.error(msg)
      }
      let { status, data, message } = await Api.updateClientData(params);
      if (Number(status) === 200) {
        this.getData()
        this.$message.success('成功')
      }
    },
    async addClientData(){
      let params = await this.getFormData()
      let {resultFlag,msg} = this.validateFormData(params)
      if(!resultFlag){
        return this.$message.error(msg)
      }
      let { status, data, message } = await Api.addClientData(params);
      if (Number(status) === 200) {
        this.$router.push({
          path: this.$route.path,
          query: {
            _type: 'edit',
            CLIENT_ID: data.CLIENT_ID,
            STATUS: data.STATUS,
          }
        })
        this.getData();
        this.$message.success('成功')
      }
    },
    /*
    * 表单设置值
    * */
    setFormData(result= {}){
      this.$refs.clientCommon.$refs.clientCommon.formData = result;
      this.thead.forEach(item =>{
        if(item !== "THead"){
          this.$refs.clientTag.$refs[item][0].formData = result
        }
      })
    },
    /*
    * 表单取值
    * @return {}  扁平化对象
    * */
    getFormData(){
      let params = {
        ...this.$refs.clientCommon.$refs.clientCommon.formData
      }

      this.thead.forEach(item =>{
        if(item !== "THead"){
          params = {...params,...this.$refs.clientTag.$refs[item][0].formData}
        }
      })
      return params
    },
    handleConfirm() {},
    handleClose() {
      this.$emit('close')
    },
    /**
     * @description 取消 -> 返回
    */
    cancelEdit() {
      this.$router.push({path: '/client', query: {}})
    },
    validateFormData(data={}){
      let resultFlag = true,msg = '';
      let ASN_List_data = [data.ASN_PREFIX,data.ASN_PREFIX_START,data.ASN_PREFIX_NEXT,data.ASN_PREFIX_END]
      const hasValue = (val)=>{
        return val === 0 || !!val
      }
      /*4个值都必须有值或者必须没值*/
      if(ASN_List_data.some(item => hasValue(item))){
        if(!ASN_List_data.every(item => hasValue(item))){
          resultFlag = false
          msg = 'ASN_PREFIX,ASN_PREFIX_START,ASN_PREFIX_NEXT,ASN_PREFIX_END 必须同时有值或者同时无值'
        }else {
          /*都要大于开始值*/
          if(!(data.ASN_PREFIX_END > data.ASN_PREFIX_START)){
            resultFlag = false
            msg = 'ANS PREFIX结束值 必须大于 开始值'
          }

          if(!(data.ASN_PREFIX_NEXT > data.ASN_PREFIX_START)){
            resultFlag = false
            msg = 'ANS PREFIX下一个 必须大于 开始值'
          }
        }
      }

      if(data.ASN_DATE_FORMAT){
        if(!ASN_List_data.every(item=> hasValue(item) )){
          resultFlag = false
          msg = 'DATE_FORMAT有值，ASN_PREFIX,ASN_PREFIX_START,ASN_PREFIX_NEXT,ASN_PREFIX_END 必须同时有值'
        }
      }
      return {
        resultFlag,
        msg
      }
    }
  }
}