<template>
  <div class="clientTag">
    <el-tabs type="border-card" v-model="tagName">
      <el-tab-pane v-for="(tagForm, index) in tagFormConfig" :key="'tagForm' + index" :label="tagForm.label" :name="tagForm.value">
        <template v-if="tagForm.tagConfigs.some(item=>!!item.tabChildGroup)">
          <div class="tab_child">
            <div class="tab_child_title">需求订单编号</div>
            <com-form
              :ref="tagForm.value"
              label-width="160px"
              label-position="right"
              :col="2"
              :configs="tagForm.tagConfigs.filter(item=>item.tabChildGroup === '需求订单编号')"
            ></com-form>
          </div>
          <div class="tab_child">
            <div class="tab_child_title">Demand Decatalogused Order ID</div>
            <com-form
              :ref="tagForm.value"
              label-width="160px"
              label-position="right"
              :col="2"
              :configs="tagForm.tagConfigs.filter(item=>item.tabChildGroup === 'Demand Decatalogused Order ID')"
            ></com-form>
          </div>
        </template>
        <template v-else>
          <com-form
            :ref="tagForm.value"
            label-width="160px"
            label-position="right"
            :col="2"
            :configs="tagForm.tagConfigs"
          ></com-form>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { formConfig } from "../config/formConfig.js"
import {tabChildPaneName} from "../../../configs/tabTranslate"

export default {
  name: 'clientTag',
  props:{
    currentTag:String
  },
  data() {
    const config = formConfig(this);
    return {
      tagName: config.tagFormConfig[0].value,
      formData: {},
      tagFormConfig: config.tagFormConfig,
      tabChildPaneName:tabChildPaneName
    }
  },
  watch:{
    currentTag(val){
      if (val){
        this.tagName = val
      }
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .tab_child{
    border: 1px solid #ccc;
    padding: 30px;
    position: relative;
    margin-top: 40px;
    .tab_child_title{
      position: absolute;
      height: 30px;
      background: #fff;
      top: -10px;
      z-index: 999;
    }
  }
</style>